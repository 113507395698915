import "isomorphic-fetch"

import {
  turnOnReactAdmin,
  turnOnReactCommunicationSettings,
  turnOnReactOfficeUsers,
  turnOnReactOfficeGroups,
  turnOnReactSetupPayers,
  turnOnReactClaimEntry,
  turnOnReactClaims,
  turnOnReactEligibility,
  turnOnReactUserWizard,
  turnOnReactClaimSubmission,
  turnOnReactClaimsPayerMapping,
  turnOnReactGroupWizard,
  turnOnReactProfile,
  turnOnReactPayerListDashboard,
  turnOnReactPayerList,
  turnOnReactPayerListDetails,
  turnOnReactSupportTicketManagement,
  turnOnReactDashboard,
  turnOnReactFissDDE,
  turnOnReactManageFissUsers,
} from "./turn-on-react-apps"

export async function getApps() {
  const { paths: appPaths } = await fetch(process.env.NODE_ENV === 'development' ? './src/rcm-micro-app-paths.json' : '/api/v1/rcm-container-config').then(response => response.json());

  let results = [
    {
      root: appPaths.analytics,
      routes: [
        { 
          hashPattern: '^#\\/analytics',
        }
      ]
    },
    {
      root: appPaths.dataAnalytics,
      routes: [
        { 
          hashPattern: '^#\\/data-analytics',
        }
      ]
    },
    {
      root: appPaths.dm,
      routes: [
        { hashPattern: '^#\\/denial-management' }
      ]
    },
    {
      root: appPaths.payments,
      routes: [
        { hashPattern: '^#\\/payments' }
      ]
    },
    {
      root: appPaths.remits,
      routes: [
        { hashPattern: '^#\\/remits' }
      ]
    },
    {
      root: appPaths.statements,
      routes: [
        { hashPattern: '^#\\/statements' }
      ]
    },
    {
      root: appPaths.selfService,
      routes: [
        { hashPattern: '^#\\/self-service' }
      ]
    },
    {
      root: appPaths.auths,
      routes: [
        { hashPattern: '^#\\/auths'}
      ]
    }
  ];

  let rcmApp = {
    routes: [
      { hashPattern: '^#\\/realclean' },
      { hashPattern: '^#\\/reports' },

      // All the admin routes since it doesn't have a prefix >:o
      { hashPattern: '^#\\/admin/announcement-archive' },
      { hashPattern: '^#\\/covid-splash' },
      { hashPattern: '^#\\/login', hasNavbar: false, redirectUrl: '/login/#/' },
      { hashPattern: '^#\\/change-password', hasNavbar: false , redirectUrl: '/login/#/change-password'},
      { hashPattern: '^#\\/update-email', hasNavbar: false },
      { hashPattern: '^#\\/verify-location', hasNavbar: false, redirectUrl: '/login/#/verify-location' },
      { hashPattern: '^#\\/forgot-password/answer', hasNavbar: false , redirectUrl: '/login/#/forgot-password/answer'}, 
      { hashPattern: '^#\\/forgot-password$', hasNavbar: false , redirectUrl: '/login/#/forgot-password'},
      { hashPattern: '^#\\/recover-username', hasNavbar: false, redirectUrl: '/login/#/recover-username'  },
      { hashPattern: '^#\\/security-questions', hasNavbar: false, redirectUrl: '/login/#/security-questions' },
      { hashPattern: '^#\\/verify-mfa', hasNavbar: false, redirectUrl: '/login/#/verify-mfa' },
      { hashPattern: '^#\\/setup-mfa', hasNavbar: false, redirectUrl: '/login/#/setup-mfa' },

      // Converting to react apps
      {
        hashPattern: '^#\\/claims/(?!(create-claim|create-secondary/advanced(.*)|(.*)\/edit/advanced|(.*)\/multi-edit/advanced|(.*)\/view/advanced|(.*)\/clone/advanced(.*)|(.*)/edit/adjustments|(.*)/multi-edit/adjustments|(.*)/view/adjustments|(.*)\/clone/adjustments(.*)|create-secondary/adjustments(.*)|(.*)\/verify-eligibility|claim-submission|payer-mapping/(.*)))',
        officeOption: ['7S'],
        turnOnReact: turnOnReactClaims
      },
      {
        hashPattern: '^#\\/eligibility',
        officeOption: ['7T'],
        turnOnReact: turnOnReactEligibility
      },
      {
        hashPattern: '^#\\/claims/create-claim(.*)',
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry
      },
      {
        hashPattern: '^#\\/claims/(.*)\/edit/advanced',
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry
      },
      {
        hashPattern: '^#\\/claims/(.*)\/multi-edit/advanced',
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry
      },
      {
        hashPattern: '^#\\/claims/(.*)\/view/advanced(.*)',
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry
      },
      {
        hashPattern: '^#\\/claims/create-secondary/advanced(.*)',
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry
      },
      {
        hashPattern: '^#\\/claims/(.*)\/clone/advanced(.*)',
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry
      },
      {
        hashPattern: "^#\\/claims/(.*)\/clone/adjustments(.*)",
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry,
      },
      {
        hashPattern: '^#\\/admin',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactAdmin
      },
      {
        hashPattern: '^#/$',
        officeOption: ['7Q'],
        turnOnReact: turnOnReactDashboard
      },
      {
        hashPattern: '^#\\/communication-settings',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactCommunicationSettings,
      },
      {
        hashPattern: '^#\\/office-users',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactOfficeUsers
      },
      {
        hashPattern: '^#\\/office-groups',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactOfficeGroups
      },
      {
        hashPattern: '^#\\/setup-payers',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactSetupPayers
      },
      {
        hashPattern: '^#\\/payer-list-dashboard',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactPayerListDashboard
      },
      {
        hashPattern: '^#\\/user-wizard',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactUserWizard
      },
      {
        hashPattern: '^#\\/claims/claim-submission',
        officeOption: ['7C'],
        turnOnReact: turnOnReactClaimSubmission
      },
      {
        hashPattern: '^#\\/claims/payer-mapping(.*)',
        officeOption: ['7C'],
        turnOnReact: turnOnReactClaimsPayerMapping
      },
      {
        hashPattern: '^#\\/group-wizard',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactGroupWizard
      },
      {
        hashPattern: '^#\\/profile',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactProfile
      },
      {
        hashPattern: '^#\\/payer-list',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactPayerList
      },
      {
        hashPattern: '^#\\/payer-list-details',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactPayerListDetails
      },
      {
        hashPattern: '^#\\/support-ticket',
        officeOption: ['7Z'],
        turnOnReact: turnOnReactSupportTicketManagement
      },
      {
        hashPattern: '^#\\/admin/manage-fiss-users',
        officeOption: ['7P'],
        turnOnReact: turnOnReactManageFissUsers
      },
      {
        hashPattern: '^#\\/claims/dde',
        officeOption: ['7P'],
        turnOnReact: turnOnReactFissDDE
      },
      {
        hashPattern: "^#\\/claims/(.*)/edit/adjustments",
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry,
      },
      {
        hashPattern: "^#\\/claims/(.*)/multi-edit/adjustments",
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry,
      },
      {
        hashPattern: "^#\\/claims/(.*)/view/adjustments(.*)",
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry,
      },
      {
        hashPattern: '^#\\/claims/create-secondary/adjustments(.*)',
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry
      },
      {
        hashPattern: "^#\\/claims/(.*)\/verify-eligibility",
        officeOption: ['7O'],
        turnOnReact: turnOnReactClaimEntry,
      },
    ],
    root: appPaths.rcm
  };

  results.push(rcmApp);

  return { results, appPaths };
}
