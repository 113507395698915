import 'core-js';
import 'regenerator-runtime';

import './images/logo-availity-essentials-pro-dev.png';
import './images/logo-availity-essentials-pro-tst.png';
import './images/logo-availity-essentials-pro-qua.png';
import './images/logo-availity-essentials-pro-q01.png';
import './images/logo-availity-essentials-pro.png';
import './images/logo-availity-dark.png';

import { getApps } from "./apps.resource"
import { handleTurnOnReactRoute } from "./turn-on-react-apps"

let apps = [];
let appPaths = null;
let appContent = null;

initialize();

function loadMatchingApp() {
  let match = matchRoute(window.location);
  if (match && match.route) {
    if (match.route.turnOnReact && !match.route.reactOptionInitialized) {
      handleTurnOnReactRoute(apps, match.app, match.route, appPaths).then(x => {
        loadMatchingApp();
      });
    } else {
      loadMatch(match);
    }
  }
}

function loadMatch(match) {
  if (match.route.redirectUrl) {
    handleRouteRedirect(match.route.redirectUrl);
    return;
  }
  loadAppContent(match.app);
  configureNavbar(match.route);
  updateTitle();
}

function getQueryStringParam(name) {
  var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (!results) {
    return undefined;
  }
  return results[1] || undefined;
}

function handleRouteRedirect(routeRedirectUrl) {
  var searchParams = new URLSearchParams();

  var redirectUrlParam = getQueryStringParam("redirectUrl");
  if (redirectUrlParam) {
    searchParams.append('redirectUrl', window.decodeURIComponent(redirectUrlParam));
  }

  var tokenUrlParam = getQueryStringParam("token");
  if (tokenUrlParam) {
    searchParams.append('token', tokenUrlParam);
  }

  var redirectUrl = searchParams.toString() !== '' ? `?${searchParams.toString()}` : '';

  window.location.href = routeRedirectUrl + redirectUrl;
}

function loadAppContent(app) {
  if (!app) {
    return;
  }

  if (appContent.contentWindow.location.href.startsWith(app.root)) {
    appContent.contentWindow.location.hash = window.location.hash;
    return;
  }

  let location = `${app.root}${window.location.hash}`;
  appContent.contentWindow.location.replace(location);
}

function matchRoute(location) {
  let match = {
    app: null,
    route: null
  };

  apps.forEach(app => {
    let matchingRoute = app.routes.find(m => new RegExp(m.hashPattern).test(location.hash));
    if (matchingRoute) {
      match.app = app;
      match.route = matchingRoute;
      return;
    }
  });

  return match;
}

async function registerApps() {
  getApps().then(x => {
    apps = x.results;
    appPaths = x.appPaths;
    appContent = document.querySelector('#app-content');

    appContent.addEventListener('load', () => {
      updateApplicationUrl(true);
      setAppContentEvents();
    });

    setDefaultRoute();
    loadMatchingApp();
  });
}

function select2maskBlur() {
  let select2mask = appContent.contentWindow.window.document.querySelector('#select2-drop-mask');
  if (select2mask) {
    select2mask.click();
  }
}

function configureNavbar(route) {
  var hasNavbar = !route || route.hasNavbar === false ? false : true;
  if (typeof window.navbar?.hasNavbar === "function") {
    window.navbar.hasNavbar(hasNavbar);
  }
}

function updateApplicationUrl(checkMatchingApp) {
  let location = `${window.location.origin}/${appContent.contentWindow.window.document.location.hash}`;
  window.history.replaceState(null, appContent.contentWindow.window.document.title, location);

  if (checkMatchingApp) {
    loadMatchingApp();
  }

  updateTitle();
}

function initialize() {
  window.addEventListener('load', async () => {
    // Because each localhost port is on different domains, we need to manually apply the token to session storage
    if (process.env.NODE_ENV === 'development') {
      checkForExistingSession();
    }

    appContent = document.querySelector('#app-content');
    if (appContent) { //if cached and loads off back button / else load function adds these
      setAppContentEvents();
    }

    await registerApps();

    window.addEventListener("hashchange", () => {
      loadMatchingApp();
    });

    window.addEventListener('blur', function () {
      let openNavbarDropdown = document.querySelector('#navbar-content').querySelector('.dropdown .show');
      if (openNavbarDropdown) {
        const navLink = openNavbarDropdown.parentElement.querySelector('a.nav-link');
        if (navLink) {
          navLink.click();
        }
      }
    });
  });
}

function setAppContentEvents() {
  appContent.contentWindow.addEventListener('hashchange', updateApplicationUrl);
  appContent.contentWindow.addEventListener('blur', select2maskBlur);
  setupRouteChangeDetectionForAngularApplication();
}

function setDefaultRoute() {
  window.location.href = window.location.href.includes("/#/") ? window.location.href : window.location.origin + "/#/";
}

function setupRouteChangeDetectionForAngularApplication() {
  ((history) => {
    var pushState = history.pushState;
    history.pushState = (state, key, path) => {
      setTimeout(function () {
        updateApplicationUrl();
      });

      return pushState.apply(history, [state, key, path]);
    };
  })(appContent.contentWindow.history);
}

function updateTitle() {
  setTimeout(function () {
    let title = appContent.contentWindow.window.document.title;
    window.document.title = title;
  });
}

/*
  For cases when the login screen is on a separate domain session storage is not maintained.
  We get sent the token in a URL, so we should add it to session storage and remove the token from the URL.
*/
function checkForExistingSession() {
  const queryParams = parseQueryParams();
  if (!queryParams) {
    return;
  }

  // Grab the token from the url and set sessionStorage
  if (queryParams.token) {
    window.sessionStorage.setItem('rcm.authentication.jws', queryParams.token);
  }

  // Grab the username from the url and set the sessionStorage
  if (queryParams.username) {
    window.sessionStorage.setItem('current-user', queryParams.username);
  }

  // Remove Query Params
  window.location.href = window.location.origin;
}

/*
  Pull out query params in an IE supported way
*/
function parseQueryParams() {
  if (window.location.href.indexOf('?') > -1) {
    const paramString = window.location.href.slice(window.location.href.indexOf('?') + 1);
    const parameters = paramString.split('&');
    return parameters.reduce((data, item) => {
      const [rawKey, rawValue] = item.split('=');
      const key = decodeURIComponent(rawKey);
      const value = decodeURIComponent(rawValue);

      data[key] = value;

      return data;
    }, {});
  }
}
