import "isomorphic-fetch"

async function getOfficeOption(officeOption) {
  const response = await fetch(
    process.env.NODE_ENV === 'development' ? './src/office-option.json' : `/api/v1/preferences/${officeOption}`,
    {
      headers: {
        'Authorization': `JWS ${sessionStorage.getItem('rcm.authentication.jws')}`
      }
    })
    
  return await response.json();
}

export async function checkTurnOnReact(possibleReactApp) {
  let defaultVal = possibleReactApp
    .officeOption
    .map(option => ({ code: option, value: 'N'}));
  
  if (process.env.NODE_ENV === 'development' || sessionStorage.getItem('rcm.authentication.jws')) {
    try {
      const responses = [];

      for (let index = 0; index < possibleReactApp.officeOption.length; index++) {
        const response = await getOfficeOption(possibleReactApp.officeOption[index]);
        responses.push(response);
      }

      possibleReactApp.reactOptionInitialized = true;
      return responses;
    } catch {
      possibleReactApp.reactOptionInitialized = true;
      return defaultVal;
    }
  } else {
    possibleReactApp.reactOptionInitialized = true;
    return defaultVal;
  }
}

function isOfficeOptionOn(response, officeOptions) {
  var validResponses = response.filter(res => 
    res.value?.length > 0 &&
    officeOptions.includes(res.code)    
  );

  if (!validResponses?.length) {
    return false;
  }

  return validResponses
    .map(option => option.value)
    .every(value => value === 'Y');
}

export async function handleTurnOnReactRoute(apps, matchedApp, possibleReactRoute, appPaths) {
  const response = await checkTurnOnReact(possibleReactRoute);
  const turnOn = isOfficeOptionOn(response, possibleReactRoute.officeOption);
  if(turnOn && possibleReactRoute.turnOnReact) {
    matchedApp.routes = matchedApp.routes.filter(function( obj ) {
      return obj.hashPattern !== possibleReactRoute.hashPattern;
    });
    possibleReactRoute.turnOnReact(apps, matchedApp, appPaths);
  }
}

export function turnOnReactCommunicationSettings(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [{ hashPattern: "^#\\/communication-settings" }],
  });
}

export function turnOnReactClaims(apps, matchedApp, appPaths) {
  const convertedPaths = [
    'secondary-claims-management',
    'claim-status' , 
    'claim-postback-rules',
    'ama-terms-and-conditions',
    'clear-claim-connection',
    'resources',
    'edit-error',
    'claim-submission',
    "payer-mapping/(.*)",
    '(.*)\/history',
    'select-eop',
    '(.*)\/notes',
    'settings/(.*)',
    'create-claim(.*)',
    '(.*)\/edit/advanced',
    'dde',
    '(.*)\/multi-edit/advanced',
    '(.*)\/view/advanced(.*)',
    'create-secondary/advanced(.*)',
    "(.*)/edit/adjustments",
    "(.*)/multi-edit/adjustments",
    "(.*)/view/adjustments(.*)",
    '(.*)\/clone/advanced(.*)',
    "(.*)\/clone/adjustments(.*)",
    'create-secondary/adjustments(.*)',
    "(.*)\/verify-eligibility",
  ];

  matchedApp.routes.push({ hashPattern: `^#\/claims\/(?:(?!${convertedPaths.join('|')}))` });

  apps.unshift({
    root: appPaths.claims,
    routes: convertedPaths.map((path) =>({ hashPattern: `^#\\/claims/${path}` }))
  });
}

export function turnOnReactClaimEntry(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.claims,
    routes: [
        { hashPattern: '^#\\/claims/create-claim(.*)' },
        { hashPattern: '^#\\/claims/(.*)\/edit/advanced' },
        { hashPattern: "^#\\/claims/(.*)\/multi-edit/advanced" },
        { hashPattern: '^#\\/claims/(.*)\/view/advanced(.*)' },
        { hashPattern: '^#\\/claims/create-secondary/advanced(.*)' },
        { hashPattern: "^#\\/claims/(.*)/edit/adjustments" },
        { hashPattern: "^#\\/claims/(.*)/multi-edit/adjustments" },
        { hashPattern: "^#\\/claims/(.*)/view/adjustments(.*)" },
        { hashPattern: '^#\\/claims/(.*)\/clone/advanced(.*)' },
        { hashPattern: "^#\\/claims/(.*)\/clone/adjustments(.*)" },
        { hashPattern: '^#\\/claims/create-secondary/adjustments(.*)' },
        { hashPattern: "^#\\/claims/(.*)\/verify-eligibility" },
    ],
  });
}

export function turnOnReactEligibility(apps, matchedApp, appPaths) {

  matchedApp.routes.push({ hashPattern: '^#\/eligibility\/(?:(?!batches|eligibility-dashboard|coverages|eligibility-settings(.*)|selfpay(.*)))' });

  apps.unshift({
    root: appPaths.eligibility,
    routes: [
        { hashPattern: '^#\\/eligibility/batches' },
        { hashPattern: '^#\\/eligibility/coverages' },
        { hashPattern: '^#\\/eligibility/eligibility-settings(.*)' },
        { hashPattern: '^#\\/eligibility/selfpay(.*)' },
        { hashPattern: '^#\\/eligibility/eligibility-dashboard' },
    ],
  });
}

export function turnOnReactAdmin(apps, matchedApp, appPaths) {

  matchedApp.routes.push({ hashPattern: `^#\/admin/announcement-archive`});

  apps.unshift({
    root: appPaths.admin,
    routes: [
      { hashPattern: '^#\\/admin(?:(?!/announcement-archive))' }
    ],
  });
}

export function turnOnReactDashboard(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
      { hashPattern: '^#\\/$' }
    ],
  });
}

export function turnOnReactProfile(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
	  { hashPattern: '^#\\/profile' }
	],
  });
}

export function turnOnReactPayerList(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
      { hashPattern: '^#\\/payer-list' }
    ],
  });
}

export function turnOnReactPayerListDetails(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
      { hashPattern: '^#\\/payer-list-details' }
    ],
  });
}

export function turnOnReactOfficeUsers(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
	  { hashPattern: '^#\\/office-users' },
    ],
  });
}

export function turnOnReactOfficeGroups(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
	  { hashPattern: '^#\\/office-groups' }
    ],
  });
}

export function turnOnReactSetupPayers(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
	  { hashPattern: '^#\\/setup-payers' },
    ],
  });
}

export function turnOnReactPayerListDashboard(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
	  { hashPattern: '^#\\/payer-list-dashboard/' },
    ],
  });
}

export function turnOnReactUserWizard(apps, matchedApp, appPaths) {
  const convertedPaths = [
    'setup-user',
    'user-groups' , 
    'allowed-offices',
    'overrides',
    'summary'
  ];

  matchedApp.routes.push({ hashPattern: `^#\/user-wizard\/(?:(?!${convertedPaths.join('|')}))` });

  apps.unshift({
    root: appPaths.admin,
    routes: convertedPaths.map((path) =>({ hashPattern: `^#\\/user-wizard/${path}` }))
  });
}

export function turnOnReactClaimSubmission(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.claims,
    routes: [
      { hashPattern: '^#\\/claims/claim-submission' }
    ],
  });
}

export function turnOnReactClaimsPayerMapping(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.claims,
    routes: [
	    { hashPattern: '^#\\/claims/payer-mapping(.*)' }
    ],
     });
}

export function turnOnReactGroupWizard(apps, matchedApp, appPaths) {
  const convertedPaths = [
    'setup-group',
    'security-permissions' , 
    'add-users',
    'summary'
  ];

  matchedApp.routes.push({ hashPattern: `^#\/group-wizard\/(?:(?!${convertedPaths.join('|')}))` });

  apps.unshift({
    root: appPaths.admin,
    routes: convertedPaths.map((path) =>({ hashPattern: `^#\\/group-wizard/${path}` }))
  });
}

export function turnOnReactSupportTicketManagement(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [
	  { hashPattern: '^#\\/support-ticket' },
    ],
  });
}

export function turnOnReactManageFissUsers(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.admin,
    routes: [{ hashPattern: "^#\\/admin/manage-fiss-users" }],
  });
}

export function turnOnReactFissDDE(apps, matchedApp, appPaths) {
  apps.unshift({
    root: appPaths.claims,
    routes: [{ hashPattern: "^#\\/claims/dde" }],
  });
}